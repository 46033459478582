import {action, makeObservable, observable} from 'mobx';
import {ca2domains, ca2types} from '../../api/proto';
import APILayer from '../APILayer';
import DomainContact from './DomainContact';
import DomainsStore from './DomainsStore';

export class DomainContactsStore extends APILayer {
  constructor(public domainsStore: DomainsStore) {
    super(domainsStore.app);
    makeObservable(this);
  }

  @observable isInit: boolean = false;

  @observable list: DomainContact[] = [];

  @action init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadContacts();

    this.isInit = true;
  };

  loadContacts = async () => {
    const {res, error} = await this.request({
      domains: {
        contactsList: {},
      },
    });

    if (res?.domains?.contactsList) {
      this.processLoadContacts_(res.domains.contactsList);
    }

    return {error, res: res?.domains?.contactsList};
  };

  private processLoadContacts_ = (res: ca2domains.IContactsListResponse) => {
    if (res.contacts?.length) {
      this.list = res.contacts.map((contact) => new DomainContact(contact, this.domainsStore));
    }
  };

  findContactById = (contactId?: number | null) => {
    return this.list.find((contact) => contactId && contact.id === contactId) || null;
  };

  createOrUpdateContact = async (contact: ca2types.IDomainContact) => {
    const {res, error} = await this.request({
      domains: {
        createOrUpdateContact: {
          contact,
        },
      },
    });

    if (res?.domains?.createOrUpdateContact) {
      this.processCreateOrUpdateContact_(res.domains.createOrUpdateContact);
    }

    return {error, res: res?.domains?.createOrUpdateContact};
  };

  deleteContact = async (contactId: number, replacementContactId?: number | null) => {
    const {res, error} = await this.request({
      domains: {
        deleteContact: {
          contactId,
          replacementContactId,
        },
      },
    });

    if (res?.domains?.deleteContact) {
      this.processDeleteContact_(res.domains.deleteContact, contactId, replacementContactId);
    }

    return {error, res: res?.domains?.deleteContact};
  };

  private processDeleteContact_ = (
    res: ca2domains.IDeleteContactResponse,
    contactId: number,
    replacementContactId?: number | null,
  ) => {
    if (!res.errors?.length) {
      if (replacementContactId) {
        const contactToDelete = this.list.find((contact) => contact.id === contactId);
        const replacementContact = this.list.find((contact) => contact.id === replacementContactId);

        if (contactToDelete && replacementContact) {
          replacementContact.domainIds = [...replacementContact.domainIds, ...contactToDelete.domainIds];
        }
      }

      this.list = this.list.filter((contact) => contact.id !== contactId);
    }
  };

  private processCreateOrUpdateContact_ = (res: ca2domains.ICreateOrUpdateContactResponse) => {
    if (res.contact) {
      const existingContactIndex = this.list.findIndex((contact) => contact.id === res.contact?.id);

      if (existingContactIndex !== -1) {
        this.list[existingContactIndex] = new DomainContact(res.contact, this.domainsStore);
      } else {
        this.list.unshift(new DomainContact(res.contact, this.domainsStore));
      }
      this.list = this.list.slice();
    }
  };

  @action reset = () => {
    this.list = [];

    this.isInit = false;
  };
}

export default DomainContactsStore;
