import {Box, styled} from '@mui/material';
import Button from '@mui/material/Button/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/arrow-left.svg';
import Typography from '../../components/UI/Typography';
import {ResizeServerStep} from './ResizeServerPage';

interface IResizeServerPageTopBarProps {
  currentStep: ResizeServerStep;
  onBack?(): void;
}

const Delimiter = styled(Box)(({theme}) => ({
  color: theme.palette.body.placeholder,
  margin: `0 ${theme.spacing(3)}`,
}));

const ResizeServerPageTopBar: React.FC<IResizeServerPageTopBarProps> = (props) => {
  const {t} = useTranslation();

  const titlesData = Object.entries({
    [ResizeServerStep.CHOOSE_PLAN]: t('resize_server_choose_plan_step'),
    [ResizeServerStep.CONFIRMATION]: t('resize_server_confirmation_step'),
  });

  return (
    <div className="create-server-page-top-bar">
      {props.currentStep !== ResizeServerStep.CHOOSE_PLAN ? (
        <div className="create-server-page-top-bar__back">
          <Button
            className="create-server-page-top-bar__back-btn"
            variant="outlined"
            size="large"
            onClick={props.onBack}
            sx={(theme) => ({padding: theme.spacing(1), minWidth: 0})}
          >
            <ArrowLeftIcon width={24} height={24} />
          </Button>
        </div>
      ) : null}

      <div className="create-server-page-top-bar__title-wrap">
        <Typography variant="body3" color="body.tertiary">
          {t('resize_server_title')}
        </Typography>
        <div className="create-server-page-top-bar__title-bottom">
          {titlesData.map(([step, title], idx) => (
            <React.Fragment key={step}>
              <Typography
                variant="h4"
                component="span"
                sx={(theme) => ({
                  color: props.currentStep === +step ? theme.palette.body.primary : theme.palette.body.tertiary,
                })}
                color={props.currentStep === +step ? 'body.primary' : 'body.tertiary'}
              >
                {title}
              </Typography>
              {idx !== titlesData.length - 1 ? <Delimiter component="span">/</Delimiter> : null}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResizeServerPageTopBar;
