import {LoadingButton} from '@mui/lab';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import PasswordInput from '../../components/UI/PasswordInput';
import PasswordSmartInput, {PASSWORD_VALIDATIONS} from '../../components/UI/PasswordSmartInput';
import Typography from '../../components/UI/Typography';
import {useStore} from '../../stores/AppStore';

interface IProps {
  onSubmit?(): void;
}

const CreatePasswordForm: React.FC<IProps> = observer((props) => {
  const {userStore, notification, utils} = useStore();
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required()
        .min(utils.minPasswordLength)
        .matches(PASSWORD_VALIDATIONS.capitalLetterRegexp)
        .matches(PASSWORD_VALIDATIONS.numberCharRegexp)
        .matches(PASSWORD_VALIDATIONS.specialCharRegexp),
      passwordConfirmation: Yup.string()
        .required(t('auth_personal_info_form_password_confirm_required'))
        .nullable()
        .oneOf([Yup.ref('password'), null], t('auth_personal_info_form_password_confirm_validation')),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      const {res, error} = await userStore.createNewPassword({
        newPassword: values.password,
        newPasswordConfirmation: values.passwordConfirmation,
      });

      if (error) {
        notification.error(error.message);
        setLoading(false);
        return;
      }

      if (res?.user) {
        notification.success(t('auth_create_password_success_message'));
        props.onSubmit && props.onSubmit();
      }

      setLoading(false);
    },
    onReset: () => {
      setLoading(false);
    },
  });

  return (
    <form className="auth-page__form" onSubmit={formik.handleSubmit}>
      <Typography variant="h1" gutterBottom>
        {t('auth_create_password_form_title')}
      </Typography>
      <Typography variant="body1">{t('auth_create_password_form_description_1')}</Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({marginBottom: theme.spacing(6), color: theme.palette.body.tertiary})}
      >
        {t('auth_create_password_form_description_2')}
      </Typography>

      <PasswordSmartInput
        fullWidth
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        placeholder={t('auth_personal_info_form_password_placeholder')}
        label={t('auth_personal_info_form_password_label')}
        error={!!formik.errors.password}
        helperText={formik.errors.password}
      />

      <PasswordInput
        fullWidth
        name="passwordConfirmation"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.passwordConfirmation}
        placeholder={t('auth_personal_info_form_password_confirm_placeholder')}
        label={t('auth_personal_info_form_password_confirm_label')}
        error={!!formik.errors.passwordConfirmation}
        helperText={formik.errors.passwordConfirmation}
      />

      <LoadingButton fullWidth variant="contained" type="submit" size="large" loading={loading}>
        {t('auth_personal_info_form_submit')}
      </LoadingButton>
    </form>
  );
});

export default CreatePasswordForm;
