import axios from 'axios';
import {compare} from 'compare-versions';
import {action, computed, makeObservable, observable} from 'mobx';
import qs from 'query-string';

import {UPDATE_CHECK_INTERVAL} from '../constants';
import getQueryStringParam from '../utils/getQueryStringParam';
import wait from '../utils/wait';
import {AppStore} from './AppStore';

export default class AppVersionUpdater {
  constructor(public app: AppStore) {
    makeObservable(this);

    this.start();
  }

  start = async () => {
    await wait(5000);

    await this.readHostedWebVersion();
  };

  @observable public desktopAppVersion = getQueryStringParam('v');

  @observable hasNewVersion: boolean = false;
  @observable hostedVersion: string | null = null;
  @observable hostedForceUpdateWebVersion: string | null = null;

  @observable isUpdating: boolean = false;

  @observable forceUpdateModalIsOpen: boolean = false;

  @computed private get _hasVersionUpdate(): boolean {
    if (this.hostedVersion && this.app.version) {
      return compare(this.hostedVersion, this.app.version, '>');
    }
    return false;
  }

  @action setForceUpdateModalIsOpen = (open: boolean) => {
    this.forceUpdateModalIsOpen = open;
  };

  @action setHasNewVersion = (value: boolean) => {
    this.hasNewVersion = value;
  };

  @action setUpdating = (updating: boolean) => {
    this.isUpdating = updating;
  };

  compareVersions = () => {
    this.setHasNewVersion(this._hasVersionUpdate);
  };

  readHostedWebVersion = async () => {
    try {
      const res = await axios.get(`/version.json?t=${Date.now()}`, {
        params: {
          nocashe: Math.floor(Date.now() / 1000),
        },
        headers: {
          Accept: 'application/json',
        },
        timeout: 30000,
        transformResponse: (r) => r,
      });

      this.processHostedVersion(res.data);
    } catch (e) {
      console.debug(e);
    }

    this.compareVersions();

    await wait(UPDATE_CHECK_INTERVAL);

    await this.readHostedWebVersion();
  };

  reload = () => {
    const qsPrms = qs.parse(window.location.search);

    qsPrms['wv'] = this.hostedVersion;

    if (this.desktopAppVersion) {
      qsPrms['v'] = this.desktopAppVersion;
    }

    window.location.replace(window.location.origin + window.location.pathname + `?${qs.stringify(qsPrms)}`);
  };

  update = async () => {
    this.setUpdating(true);

    await wait(1000);

    this.reload();

    this.setUpdating(false);
    this.setHasNewVersion(false);
    this.setForceUpdateModalIsOpen(false);
  };

  @action processHostedVersion = (data?: string) => {
    if (typeof data !== 'string') {
      return;
    }

    try {
      const res = JSON.parse(data);
      if (res?.version) {
        this.hostedVersion = res.version;
      }
      if (res?.forceUpdateVersion) {
        this.hostedForceUpdateWebVersion = res.forceUpdateVersion;
      }
    } catch (e) {
      console.debug(e);
    }
  };
}
