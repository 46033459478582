import {Provider} from 'mobx-react';
import 'react-toastify/dist/ReactToastify.css';
import AppContainer from './AppContainer';
import './assets/scss/index.scss';
import AppLoader from './components/AppLoader';
import SystemAlertBar from './components/SystemAlertBar';
import ThemeSelector from './components/ThemeWrappers';
import ToastContainer from './components/ToastContainer';
import VersionUpdateAppBar from './components/VersionUpdateAppBar';
import Modals from './modals/Modals';
import AuthProvider from './providers/AuthProvider';
import Routes from './routes';
import store from './stores/AppStore';
import AppTheme from './theme';

export const App = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <AppTheme>
          <AppContainer>
            <ThemeSelector>
              <AppLoader>
                <SystemAlertBar />
                <VersionUpdateAppBar />
                <Routes />
                <ToastContainer />
                <Modals />
              </AppLoader>
            </ThemeSelector>
          </AppContainer>
        </AppTheme>
      </AuthProvider>
    </Provider>
  );
};

export default App;
