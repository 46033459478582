import {Box} from '@mui/material';
import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Virtuoso} from 'react-virtuoso';
import Loader from '../../../components/UI/Loader';
import Typography from '../../../components/UI/Typography';
import {Plan} from '../../../stores/Plan';
import PlanCard from '../PlanCard';

interface IProps {
  plans: Plan[];
  loading: boolean;
  currentPlanId?: string | null;
  onSelectPlan?(plan: Plan): void;
  onChangePlan?(): void;
}

const PlansList: React.FC<IProps> = (props) => {
  const {t} = useTranslation();
  const totalPlans = props.currentPlanId && props.plans.length ? props.plans.length - 1 : props.plans.length;

  return (
    <div className="plans-list">
      <div className="plans-list__head">
        <Typography className="plans-list__head-title" variant="body1">
          {t('create_server_plans_list_count', {count: totalPlans})}
        </Typography>
      </div>
      <Box className={cn('plans-list__body', {'empty-list': !props.plans.length})}>
        <Virtuoso
          totalCount={props.plans.length}
          className="custom-scroll"
          itemContent={(index) => (
            <PlanCard
              plan={props.plans[index]}
              onSelectPlan={props.onSelectPlan}
              onChangePlan={props.onChangePlan}
              currentPlanId={props.currentPlanId}
            />
          )}
        />

        {!props.loading && !props.plans.length && (
          <Typography className="empty-list-title" variant="body1">
            {t('create_server_plans_list_not_found')}
          </Typography>
        )}

        <Loader loading={props.loading} />
      </Box>
    </div>
  );
};

export default PlansList;
