import {LoadingButton} from '@mui/lab';
import {Box, Button, Typography, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import * as Yup from 'yup';
import {getUserUpdatePasswordError} from '../../api/getApiError';
import {ca2users} from '../../api/proto';
import PasswordInput from '../../components/UI/PasswordInput';
import PasswordSmartInput, {PASSWORD_VALIDATIONS} from '../../components/UI/PasswordSmartInput';
import PageContentLayout from '../../layouts/PageContentLayout';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';

interface IProps {
  switchToProfileTab?(): void;
  hasPassword?: boolean;
}

const SetOrChangePasswordForm: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {userStore, notification, utils} = useStore();
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validationSchema: Yup.object({
      ...(props.hasPassword && {
        oldPassword: Yup.string().required(),
        // .min(utils.minPasswordLength)
        // .matches(PASSWORD_VALIDATIONS.capitalLetterRegexp)
        // .matches(PASSWORD_VALIDATIONS.numberCharRegexp),
        // .matches(PASSWORD_VALIDATIONS.specialCharRegexp),
      }),
      newPassword: Yup.string()
        .required()
        .min(utils.minPasswordLength)
        .matches(PASSWORD_VALIDATIONS.capitalLetterRegexp)
        .matches(PASSWORD_VALIDATIONS.numberCharRegexp)
        .matches(PASSWORD_VALIDATIONS.specialCharRegexp),
      newPasswordConfirmation: Yup.string()
        .required(t('auth_personal_info_form_password_confirm_required'))
        .nullable()
        .oneOf([Yup.ref('newPassword'), null], t('auth_personal_info_form_password_confirm_validation')),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      const {res, error} = await userStore.updatePassword(values);

      if (error) {
        notification.error(error.message);
        setLoading(false);
        return;
      }

      if (res) {
        const {errors} = res;

        if (errors?.length) {
          errors.forEach((error) => {
            const errorMessage = getUserUpdatePasswordError(error);

            switch (error) {
              case ca2users.UpdatePasswordError.UPE_INVALID_OLD_PASSWORD_ERROR:
                formik.setFieldError('oldPassword', errorMessage);
                break;
              case ca2users.UpdatePasswordError.UPE_PASSWORDS_DO_NOT_MATCH_ERROR:
              case ca2users.UpdatePasswordError.UPE_NEW_PASSWORD_IS_TOO_WEAK_ERROR:
                formik.setFieldError('newPassword', errorMessage);
                formik.setFieldError('newPasswordConfirmation', errorMessage);
                break;
              default:
                notification.error(errorMessage);
            }
          });
          setLoading(false);
          return;
        }

        if (res?.user) {
          notification.success(t('profile_form_password_update_successful'));
          props.switchToProfileTab?.();
        }
      }

      setLoading(false);
    },
    onReset: () => {
      setLoading(false);
    },
  });

  const handleForgotPassword = () => {
    navigate(Paths.ForgotPassword, {state: {email: userStore.email || ''}});
  };

  return (
    <Box sx={{width: 640, transform: 'translate(-50%, -50%)', left: '50%', top: '50%', position: 'relative'}}>
      <PageContentLayout sx={{padding: theme.spacing(4)}}>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h4" gutterBottom>
            {props.hasPassword
              ? t('profile_change_password_form_has_password_title')
              : t('profile_change_password_form_title')}
          </Typography>

          {props.hasPassword ? (
            <PasswordInput
              fullWidth
              name="oldPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.oldPassword}
              placeholder={t('profile_change_password_form_password_placeholder')}
              label={t('profile_change_password_form_password_label')}
              error={!!formik.errors.oldPassword}
              helperText={formik.errors.oldPassword}
              autoFocus
              rightAdornment={
                <Button variant="outlined" size="large" sx={{marginLeft: 'auto'}} onClick={handleForgotPassword}>
                  {t('auth_enter_password_forgot_password_button')}
                </Button>
              }
            />
          ) : null}

          <PasswordSmartInput
            fullWidth
            name="newPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            placeholder={t('profile_change_password_form_new_password_placeholder')}
            label={t('profile_change_password_form_new_password_label')}
            error={!!formik.errors.newPassword}
            helperText={formik.errors.newPassword}
          />

          <PasswordInput
            fullWidth
            name="newPasswordConfirmation"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPasswordConfirmation}
            placeholder={t('profile_change_password_form_new_password_confirm_placeholder')}
            label={t('profile_change_password_form_new_password_confirm_label')}
            error={!!formik.errors.newPasswordConfirmation}
            helperText={formik.errors.newPasswordConfirmation}
          />

          <LoadingButton fullWidth variant="contained" type="submit" size="large" loading={loading}>
            {t('action_button_submit')}
          </LoadingButton>
        </form>
      </PageContentLayout>
    </Box>
  );
});

export default SetOrChangePasswordForm;
