import {LoadingButton} from '@mui/lab';
import {Box, Button, DialogActions, DialogContent, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getApiDomainContactDeleteError} from '../api/getApiError';
import Typography from '../components/UI/Typography';
import DomainContacts, {IDomainContactsRef} from '../pages/DomainsPage/DomainContacts';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

const ModalDeleteDomainContact: React.FC<IProps> = observer((props) => {
  const {
    domainsStore: {contacts},
    notification,
  } = useStore();
  const {t} = useTranslation();

  const theme = useTheme();

  const modalRef = React.useRef<ModalDialogRef>(null);

  const [loading, setLoading] = React.useState(false);

  const domainContactsRef = React.useRef<IDomainContactsRef>(null);

  const handleClose = () => {
    modalRef.current?.close();
  };

  const handleSubmit = async () => {
    const selectedContact = domainContactsRef.current?.getSelectedContact();

    if (!props.modal.data.domainContact?.id || !selectedContact) {
      return;
    }

    if (selectedContact?.id === props.modal.data.domainContact.id) {
      notification.error(t('modal_delete_domain_contact_error_same_contact'));
      return;
    }

    setLoading(true);

    const {res, error} = await contacts.deleteContact(props.modal.data.domainContact.id, selectedContact.id);

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiDomainContactDeleteError(error);
          notification.error(errorMessage);
        });

        setLoading(false);
        return;
      }

      props.modal.trigger('submit');

      notification.success(t('modal_delete_domain_contact_success_notification'));
      handleClose();
    }

    setLoading(false);
  };

  return (
    <ModalDialog
      modal={props.modal}
      ref={modalRef}
      maxWidth="md"
      fullWidth
      headerText={t('modal_delete_domain_contact_header_text')}
    >
      <DialogContent
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: theme.palette.backgroundPrimary,
          borderBottom: `1px solid ${theme.palette.border.primary}`,
        }}
      >
        <Typography variant="body1" gutterBottom>
          {t('modal_delete_domain_contact_subtitle')}
        </Typography>
        <Box sx={{display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap'}}>
          {props.modal.data.domainContact?.associatedDomains.map((domain) => (
            <Box
              key={domain.domainId}
              component="span"
              sx={{
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                backgroundColor: theme.palette.backgroundSecondary,
                borderRadius: '8px',
              }}
            >
              {domain.name}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogContent className="custom-scroll">
        <Box sx={{maxHeight: 450}}>
          <DomainContacts
            ref={domainContactsRef}
            existingContact={props.modal.data.domainContact}
            isEditableMode
            hideAddNewContactButton
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          {t('action_button_cancel')}
        </Button>
        <LoadingButton size="large" variant="contained" color="primary" onClick={handleSubmit} loading={loading}>
          {t('modal_delete_domain_contact_submit_button')}
        </LoadingButton>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalDeleteDomainContact;
