import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import PriceFormatter from '../../utils/priceFormatter';
import BalanceOperation from '../BillingStore/BalanceOperation';
import UpcomingPayment from '../BillingStore/UpcomingPayment';
import DnsRecordsStore from './DnsRecordsStore';
import DomainsStore from './DomainsStore';

export const MAX_DOMAIN_NS_RECORDS_AMOUNT = 13;

export function standardizeNameServers(nameServers?: string[] | null) {
  if (!nameServers || !nameServers.length) {
    return ['', ''];
  }

  if (nameServers.length === 1) {
    return [nameServers[0], ''];
  }

  return nameServers;
}

export class Domain {
  constructor(public raw: ca2types.IDomain, public domainsStore: DomainsStore) {
    makeObservable(this);

    this.update_(raw);
  }

  @observable private isInit_: boolean = false;

  @observable id: Long | null = null;
  @observable name: string = '';
  @observable seller: ca2types.DomainSeller | null = null;
  @observable contactId: number | null = null;
  @observable termYears: number | null = null;
  @observable private createdAt: Long | null = null;
  @observable private expiresAt: Long | null = null;
  @observable private renewedAt: Long | null = null;
  @observable whoisPrivacy: boolean | null = null;
  @observable autoRenew: boolean | null = null;
  @observable status: ca2types.DomainStatus | null = null;
  @observable error: string | null = null;
  @observable isDefaultNameServers?: boolean | null = false;
  @observable renewalPrice?: ca2types.IAmount | null = null;

  @observable nameServers: string[] = ['', ''];
  @observable dnsRecords: DnsRecordsStore = new DnsRecordsStore(this);
  @observable upcomingPayments: UpcomingPayment[] = [];

  @observable balanceOperations: BalanceOperation[] = [];

  @computed get renewalPriceFormatter() {
    return new PriceFormatter(this.renewalPrice);
  }

  @computed get domainId() {
    return this.id?.toNumber() || null;
  }

  @computed get createdAtTimestamp() {
    return this.createdAt ? this.createdAt.toNumber() * 1000 : 0;
  }

  @computed get expiresAtTimestamp() {
    return this.expiresAt ? this.expiresAt.toNumber() * 1000 : 0;
  }

  @computed get expiresAtAfterRenewOneYearTimestamp() {
    if (!this.expiresAt) {
      return 0;
    }
    const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    return this.expiresAt.toNumber() * 1000 + oneYearInMilliseconds;
  }

  @computed get renewedAtTimestamp() {
    return this.renewedAt ? this.renewedAt.toNumber() * 1000 : 0;
  }

  @computed get daysUntilExpiration() {
    return this.calculateDaysUntilExpiration();
  }

  @computed get daysUntilExpirationLessThanMonth() {
    const days = this.daysUntilExpiration;
    return days !== null && days <= 30 ? days : null;
  }

  @computed get contact() {
    return this.domainsStore.contacts.list.find((contact) => contact.id === this.contactId) || null;
  }

  calculateDaysUntilExpiration() {
    if (!this.expiresAtTimestamp) return null;

    const now = Date.now();
    const msUntilExpiration = this.expiresAtTimestamp - now;

    if (msUntilExpiration <= 0) return null;

    const days = msUntilExpiration / (24 * 60 * 60 * 1000);

    return Math.ceil(days);
  }

  init = () => {
    if (this.isInit_) {
      return;
    }

    this.isInit_ = true;
    this.dnsRecords.init();
    this.loadDomainBillingOperations();
  };

  updateContact = async (contactId: number) => {
    const {res, error} = await this.domainsStore.request({
      domains: {
        changeDomainContact: {
          domainId: this.id,
          contactId,
        },
      },
    });

    this.domainsStore.contacts.loadContacts();

    if (res?.domains?.changeDomainContact?.domain) {
      this.processUpdateDomain_(res.domains.changeDomainContact.domain);
    }

    return {error, res: res?.domains?.changeDomainContact};
  };

  changeDomainNameServers = async (nameServers: string[], setDefaultNameServers: boolean) => {
    const {res, error} = await this.domainsStore.request({
      domains: {
        changeDomainNameServers: {
          domainId: this.id,
          nameServers,
          setDefaultNameServers,
        },
      },
    });

    if (res?.domains?.changeDomainNameServers?.domain) {
      this.processUpdateDomain_(res.domains.changeDomainNameServers.domain);
    }

    return {error, res: res?.domains?.changeDomainNameServers};
  };

  toggleAutoRenew = async () => {
    const {res, error} = await this.domainsStore.request({
      domains: {
        toggleAutoRenew: {
          domainId: this.id,
          autoRenew: !this.autoRenew,
        },
      },
    });

    if (res?.domains?.toggleAutoRenew?.domain) {
      this.processUpdateDomain_({
        ...res.domains.toggleAutoRenew.domain,
        autoRenew: res.domains.toggleAutoRenew.domain.autoRenew || false,
      });
    }

    return {error, res: res?.domains?.toggleAutoRenew};
  };

  renewDomain = async (termYears: number = 1) => {
    const {res, error} = await this.domainsStore.request({
      domains: {
        renewDomain: {
          domainId: this.id,
          termYears,
        },
      },
    });

    if (res?.domains?.renewDomain?.domain) {
      this.processUpdateDomain_(res.domains.renewDomain.domain);
    }

    return {error, res: res?.domains?.renewDomain};
  };

  @action loadDomainBillingOperations = async () => {
    const {res} = await this.domainsStore.request({
      domains: {
        upcomingPayments: {
          domainId: this.id,
        },
      },
      billing: {
        balanceOperations: {
          domainId: this.id,
        },
      },
    });

    if (res?.domains?.upcomingPayments?.payments) {
      this.processUpcomingPayments_(res.domains.upcomingPayments.payments);
    }

    if (res?.billing?.balanceOperations?.items) {
      this.processBalanceOperations_(res.billing.balanceOperations.items);
    }
  };

  @action private processUpcomingPayments_ = (payments: ca2types.IUpcomingPayment[]) => {
    this.upcomingPayments = payments.map((raw) => new UpcomingPayment(raw));
  };

  @action private processBalanceOperations_ = (items: ca2types.IBalanceOperation[]) => {
    const validTypes = new Set<ca2types.BalanceOperationType>([
      ca2types.BalanceOperationType.BOT_DOMAIN_REGISTRATION,
      ca2types.BalanceOperationType.BOT_DOMAIN_RENEWAL,
    ]);

    this.balanceOperations = items
      .filter((item) => item.type && validTypes.has(item.type))
      .map((raw) => new BalanceOperation(raw));
  };

  private processUpdateDomain_ = (rawDomain: ca2types.IDomain) => {
    this.update_({
      ...rawDomain,
      nameServers: standardizeNameServers(rawDomain.nameServers),
      isDefaultNameServers: rawDomain.isDefaultNameServers || false,
    });
  };

  @action private update_ = (raw: ca2types.IDomain) => {
    Object.assign(this, {
      ...raw,
      nameServers: standardizeNameServers(raw.nameServers),
    });
  };
}

export default Domain;
