import Decimal from 'decimal.js';
import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {ca2servers, ca2types} from '../../api/proto';
import {ZERO_AMOUNT} from '../../constants';
import {formatBytes} from '../../utils/format/formatBytes';
import PriceFormatter from '../../utils/priceFormatter';
import PaymentOption from '../BillingStore/PaymentOption';
import {PROVIDER_DIGITAL_OCEAN_ID, PROVIDER_LINODE_ID, PROVIDER_VULTR_ID} from '../Server';
import Addon from '../Server/Addon';
import PlansStore from './PlansStore';

function updatePointsRateFormatter(rateFormatter: PriceFormatter, points: number, action: 'add' | 'subtract') {
  if (points === 0) {
    return;
  }
  switch (action) {
    case 'add':
      rateFormatter.addPoints(points);
      break;
    case 'subtract':
      rateFormatter.subtractPoints(points);
      break;
  }
}

export class Plan {
  @observable id: string | null = null;
  @observable classId: string | null = null;
  @observable providerId: string | null = null;
  @observable regionIds: string[] = [];
  @observable datacenterIds: string[] = [];
  @observable cpu: ca2types.ICPU | null = null;
  @observable gpu: ca2types.IGPU | null = null;
  @observable ramSize: Long | null = null;
  @observable storageSize: Long | null = null;
  @observable private monthlyRate: ca2types.IAmount | null = null;
  @observable private hourlyRate: ca2types.IAmount | null = null;
  @observable private windowsLicenseMonthlyRate: ca2types.IAmount | null = null;
  @observable private windowsLicenseHourlyRate: ca2types.IAmount | null = null;
  @observable transfer: Long | null = null;
  @observable benefitIds: string[] = [];
  @observable availableAddonIds: string[] = [];
  @observable firstPaymentHours: number = 1;

  @observable loading: boolean = false;

  @observable monthlyRateFormatter: PriceFormatter;
  @observable hourlyRateFormatter: PriceFormatter;

  @observable windowsLicenseMonthlyRateFormatter: PriceFormatter;
  @observable windowsLicenseHourlyRateFormatter: PriceFormatter;

  @observable monthlyTotalRateFormatter: PriceFormatter;
  @observable hourlyTotalRateFormatter: PriceFormatter;

  constructor(public raw: ca2types.IServerPlan, private plansStore: PlansStore) {
    makeObservable(this);
    this.update_(raw);

    this.monthlyRateFormatter = new PriceFormatter(raw.monthlyRate);
    this.hourlyRateFormatter = new PriceFormatter(raw.hourlyRate);

    this.windowsLicenseMonthlyRateFormatter = new PriceFormatter(raw.windowsLicenseMonthlyRate);
    this.windowsLicenseHourlyRateFormatter = new PriceFormatter(raw.windowsLicenseHourlyRate);

    this.monthlyTotalRateFormatter = new PriceFormatter(raw.monthlyRate);
    this.hourlyTotalRateFormatter = new PriceFormatter(raw.hourlyRate);

    this.initImageFamily_();
  }

  @action private update_ = (raw: ca2types.IServerPlan) => {
    Object.assign(this, raw);
  };

  @action addAddonRate = (addon: Addon) => {
    updatePointsRateFormatter(this.hourlyTotalRateFormatter, addon.hourlyRateFormatter.points, 'add');
    updatePointsRateFormatter(this.monthlyTotalRateFormatter, addon.monthlyRateFormatter.points, 'add');
  };

  @action subtractAddonRate = (addon: Addon) => {
    updatePointsRateFormatter(this.hourlyTotalRateFormatter, addon.hourlyRateFormatter.points, 'subtract');
    updatePointsRateFormatter(this.monthlyTotalRateFormatter, addon.monthlyRateFormatter.points, 'subtract');
  };

  @observable isWindowsLicenseCounted = false;

  @action addWindowsLicenseRate = () => {
    if (this.isWindowsLicenseCounted) {
      return;
    }
    updatePointsRateFormatter(this.hourlyTotalRateFormatter, this.windowsLicenseHourlyRateFormatter.points, 'add');
    updatePointsRateFormatter(this.monthlyTotalRateFormatter, this.windowsLicenseMonthlyRateFormatter.points, 'add');
    this.isWindowsLicenseCounted = true;
  };

  @action subtractWindowsLicenseRate = () => {
    if (!this.isWindowsLicenseCounted) {
      return;
    }

    updatePointsRateFormatter(this.hourlyTotalRateFormatter, this.windowsLicenseHourlyRateFormatter.points, 'subtract');
    updatePointsRateFormatter(
      this.monthlyTotalRateFormatter,
      this.windowsLicenseMonthlyRateFormatter.points,
      'subtract',
    );
    this.isWindowsLicenseCounted = false;
  };

  @computed get firstHoursRateFormatter(): PriceFormatter {
    const hourlyPoints = new Decimal(this.hourlyTotalRateFormatter.points);
    const totalPoints = hourlyPoints.times(this.firstPaymentHours);
    const totalDollars = totalPoints.dividedBy(10000).toDecimalPlaces(2).toNumber();
    const formattedDollars = `$${totalDollars.toFixed(2)}`;

    const amount: ca2types.IAmount = {
      points: Long.fromNumber(totalPoints.toNumber()),
      formatted: formattedDollars,
    };

    return new PriceFormatter(amount);
  }

  @computed get allowWindowsImages(): boolean {
    return !!this.windowsLicenseHourlyRate || !!this.windowsLicenseMonthlyRate;
  }

  @computed get hasBalanceToPayFirstHours(): boolean {
    return this.firstHoursRateFormatter.isLessOrEqualPoints(this.plansStore.app.billingStore.balanceFormatter.points);
  }

  @computed get hasBalanceToPayByMonth(): boolean {
    return this.monthlyRateFormatter.isLessOrEqualPoints(this.plansStore.app.billingStore.balanceFormatter.points);
  }

  @computed get isVultr(): boolean {
    return this.providerId === PROVIDER_VULTR_ID;
  }

  @computed get isDataOcean(): boolean {
    return this.providerId === PROVIDER_DIGITAL_OCEAN_ID;
  }

  @computed get isLinode(): boolean {
    return this.providerId === PROVIDER_LINODE_ID;
  }

  @computed get isGpuServer(): boolean {
    return !!this.gpu?.model;
  }

  @computed get ramSizeFormatted(): string {
    return formatBytes(this.ramSize?.toNumber() || 0, 0);
  }

  @computed get transferFormatted(): string {
    return formatBytes(this.transfer?.toNumber() || 0, 0);
  }

  @computed get cpuInfo(): string {
    if (this.cpu?.model) {
      return `${this.cpu.count || 0} x ${this.cpu.model}`;
    }

    return `${this.cpu?.count || 0}`;
  }

  @computed get gpuInfo(): string {
    if (this.gpu?.model) {
      return `${this.gpu.count || 0} x ${this.gpu.model}`;
    }

    return `${this.cpu?.count || 0}`;
  }

  @computed get storageSizeFormatted(): string {
    return formatBytes(this.storageSize?.toNumber() || 0, 0);
  }

  @computed get addons(): Addon[] {
    return this.plansStore.app.serversStore.addons.list.filter(
      ({providerId}) => this.providerId && providerId === this.providerId,
    );
  }

  @computed get benefits(): ca2types.IBenefit[] {
    return this.plansStore.app.serversStore.benefits.findByIds(this.benefitIds);
  }

  @computed get regions(): ca2types.IRegion[] {
    return this.plansStore.app.serversStore.regions.findByIds(this.regionIds);
  }

  @computed get datacenters(): ca2types.IDatacenter[] {
    return this.plansStore.app.serversStore.datacenters.list.filter(({id}) => id && this.datacenterIds.includes(id));
  }

  @action setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  findRegionByDatacenterId = (datacenterId?: string | null): ca2types.IRegion | undefined => {
    if (!datacenterId) {
      return undefined;
    }

    return this.regions.find((region) => region.datacenterIds?.includes(datacenterId));
  };

  findDatacentersByRegionId = (regionId?: string | null): ca2types.IDatacenter[] => {
    const foundRegion = this.regions.find(({id}) => regionId && id === regionId);

    if (!foundRegion) {
      return this.datacenters;
    }

    return this.datacenters.filter(({id}) => id && foundRegion.datacenterIds?.includes(id));
  };

  @computed get provider() {
    if (!this.providerId) {
      return null;
    }

    return this.plansStore.app.serversStore.providers.findById(this.providerId) || null;
  }

  @computed get imageFamilies(): Record<string, ca2types.IImage[]> {
    return this.plansStore.app.serversStore.images.getDictionaryByFamily(this.providerId, this.allowWindowsImages);
  }

  @computed get imageFamiliesList(): string[] {
    return Object.keys(this.imageFamilies).map((family) => family);
  }

  @observable selectedImageFamilyName: string | null = null;
  @observable selectedImageId: string | null = null;

  @computed get imagesList() {
    if (!this.selectedImageFamilyName) {
      return [];
    }

    const list = this.imageFamilies[this.selectedImageFamilyName] || [];

    if (!this.allowWindowsImages) {
      return list.filter((image) => !image.isWindows);
    }

    return list;
  }

  @computed get selectedImage() {
    return this.plansStore.app.serversStore.images.findImageById(this.selectedImageId);
  }

  @computed get isWindow() {
    return !!this.selectedImage?.isWindows;
  }

  @computed get passwordAllow() {
    return !this.selectedImage?.passwordDisabled;
  }

  @action private initImageFamily_() {
    const familyName = this.imageFamiliesList[0];
    this.changeImageFamilyName(familyName);
  }

  @action changeImageFamilyName = (family: string | null) => {
    const image = family ? this.imageFamilies[family][0] : null;

    if (image) {
      this.setSelectedImageId(image.id);

      if (image.isWindows) {
        this.addWindowsLicenseRate();
      } else {
        this.subtractWindowsLicenseRate();
      }
    }

    this.selectedImageFamilyName = family;
  };

  @action setSelectedImageId = (imageId?: string | null) => {
    this.selectedImageId = imageId || null;
  };

  findImageById = (imageId: string | null) => {
    return this.plansStore.app.serversStore.images.findImageById(imageId);
  };

  @action resetSetupPlanState = () => {
    this.initImageFamily_();

    this.monthlyTotalRateFormatter.reset();
    this.hourlyTotalRateFormatter.reset();
  };

  @observable paymentOptions: PaymentOption[] = [];
  @observable hoursLeftWithBalance: number = 0;
  @observable firstPaymentWithAddonsFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);
  @observable maxAmountFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);

  loadPaymentOptions = async () => {
    this.setLoading(true);

    const {res} = await this.plansStore.request({
      servers: {
        paymentOptions: {
          planId: this.id,
        },
      },
    });

    if (res?.servers?.paymentOptions) {
      this.processLoadPaymentOptions_(res.servers.paymentOptions);
    }

    this.setLoading(false);
  };

  @action private processLoadPaymentOptions_ = (res: ca2servers.IPaymentOptionsResponse) => {
    this.hoursLeftWithBalance = res.hoursLeftWithGivenAmount || 0;
    this.paymentOptions = res.items ? res.items.map((raw) => new PaymentOption(raw)) : [];
    if (res.firstPaymentWithAddons) {
      this.firstPaymentWithAddonsFormatter = new PriceFormatter(res.firstPaymentWithAddons);
    }
    if (res.maxAmount) {
      this.maxAmountFormatter = new PriceFormatter(res.maxAmount);
    }
  };
}

export default Plan;
