import {action, computed, makeObservable, observable} from 'mobx';
import {ca2, ca2instances, ca2servers, ca2types} from '../../api/proto';
import logger from '../../utils/logger';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import {Plan} from '../Plan';
import Instance from './Instance';
import {InstanceFilter} from './InstanceFilter';

export class InstancesStore extends APILayer {
  @observable filter: InstanceFilter = new InstanceFilter(this);

  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    this.wsApi.on('instances.updatedInstances', this.onUpdateInstances_);
  }

  @observable private plans_: Plan[] = [];

  @observable instances: Instance[] = [];
  @observable isInit: boolean = false;

  @computed get filteredInstances() {
    return this.filter.filteredInstances;
  }

  @computed get hasInstances(): boolean {
    return !!this.instances.length;
  }

  @action reset = () => {
    this.instances = [];
    this.isInit = false;
  };

  @action clear = () => {
    this.filter.clearSearch();
  };

  findInstanceById = (instanceId?: number | null) => {
    return this.instances.find(({id}) => instanceId && instanceId === id) || null;
  };

  findInstanceByPlanId = (id?: string | null) => {
    return this.instances.find(({planId}) => id && id === planId) || null;
  };

  findInstancePlan = (planId?: string | null) => {
    return this.plans_.find(({id}) => planId && planId === id) || null;
  };

  @action init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadInstances_();

    this.isInit = true;
  };

  private loadInstances_ = async () => {
    const {res} = await this.request({
      instances: {
        instanceList: {
          sortOrder: ca2instances.InstanceSortOrder.ISO_CREATED_AT_DESC,
        },
      },
    });

    if (res?.instances?.instanceList) {
      logger.debug(`%c<--- Response [instanceList]`, 'color: gray', res?.instances?.instanceList.items);

      await this.loadInstancesPlans_(res.instances.instanceList.items);
      this.processLoadInstances_(res.instances.instanceList);
    }
  };

  @action private processLoadInstances_ = (res: ca2instances.IInstanceListResponse) => {
    if (res.items) {
      this.instances = res.items.map((rawInstance) => new Instance(rawInstance, this));
    }
  };

  reloadInstances = () => {
    this.loadInstances_();
  };

  private loadInstancesPlans_ = async (instances?: ca2types.IInstance[] | null) => {
    const planIds = (instances?.map(({planId}) => planId).filter(Boolean) as string[]) || [];

    if (!planIds.length) {
      return;
    }

    const {res} = await this.request({
      servers: {
        plansList: {
          ids: planIds,
        },
      },
    });

    if (res?.servers?.plansList) {
      this.processLoadInstancesPlans_(res.servers.plansList);
    }
  };

  @action private processLoadInstancesPlans_ = (res: ca2servers.IPlansListResponse) => {
    if (res.items) {
      this.plans_ = res.items.map((raw) => new Plan(raw, this.app.plansStore));
    }
  };

  private createInstanceRequest_ = (data: ca2instances.ICreateInstanceRequest) => {
    return this.request({
      instances: {
        createInstance: data,
      },
      servers: {
        plansList: {
          ids: data.planId ? [data.planId] : [],
        },
      },
    });
  };

  validateInstanceRequestData = async (data: ca2instances.ICreateInstanceRequest) => {
    const {res, error} = await this.createInstanceRequest_({...data, dryRun: true});
    return {error, res: res?.instances?.createInstance};
  };

  createInstance = async (data: ca2instances.ICreateInstanceRequest) => {
    const {res, error} = await this.createInstanceRequest_({...data});

    if (res) {
      this.processCreateInstance_(res);
    }

    return {error, res: res?.instances?.createInstance};
  };

  @action private processCreateInstance_ = (res: ca2.IServerResponse) => {
    if (res.servers?.plansList?.items?.[0]) {
      this.plans_ = [...this.plans_, new Plan(res.servers.plansList.items[0], this.app.plansStore)];
    }

    if (res?.instances?.createInstance?.instance) {
      this.instances = [...this.instances, new Instance(res.instances.createInstance.instance, this)];
    }
  };

  @action deleteInstanceProcess_ = (instanceId: number | null) => {
    this.instances = this.instances.filter(({id}) => id !== instanceId);
  };

  resizeInstance = async (instance: Instance, planId: string | null) => {
    const {res, error} = await this.request({
      instances: {
        resizeInstance: {
          instanceId: instance.id,
          planId,
        },
      },
      servers: {
        plansList: {
          ids: planId ? [planId] : [],
        },
      },
    });

    if (res) {
      this.processResizeInstance_(res);
    }

    return {error, res: res?.instances?.resizeInstance};
  };

  @action processResizeInstance_ = (res: ca2.IServerResponse) => {
    if (res.servers?.plansList?.items?.[0]) {
      this.plans_ = [...this.plans_, new Plan(res.servers.plansList.items[0], this.app.plansStore)];
    }

    if (res.instances?.resizeInstance?.instance) {
      this.onUpdateInstances_([res.instances.resizeInstance.instance]);
    }
  };

  private onUpdateInstances_ = (rawInstances: ca2types.IInstance[]) => {
    for (const raw of rawInstances) {
      const foundInstance = this.findInstanceById(raw.id);
      foundInstance?.update(raw);
    }
  };
}

export default InstancesStore;
