import {observer} from 'mobx-react';
import React from 'react';
import {animated} from 'react-spring';
import {getApiRegisterDomainError} from '../../../api/getApiError';
import {ca2domains} from '../../../api/proto';
import Div100vh from '../../../components/Div100vh';
import PaymentViewer from '../../../components/PaymentViewer';
import {useOrderTransition} from '../../../hooks/useOrderTransition';
import {useStore} from '../../../stores/AppStore';
import DomainSuggestion from '../../../stores/Domain/DomainSuggestion';
import RegisterDomainForm from './RegisterDomainForm';
import RegisterDomainTopBar from './RegisterDomainTopBar';
import RegisteredDomainConfirmation from './RegisteredDomainConfirmation';

export enum RegisterDomainStep {
  REGISTER_FORM,
  PAYMENT,
  CONFIRMATION,
}

interface IProps {
  domainSuggestion: DomainSuggestion;
  onCancelRegistration?(): void;
  onClickBackButton?(): void;
}

export const RegisterDomain: React.FC<IProps> = observer((props) => {
  const {domainsStore, notification} = useStore();
  const [prevStep, setPrevStep] = React.useState<RegisterDomainStep>(RegisterDomainStep.CONFIRMATION);
  const [currentStep, setCurrentStep] = React.useState<RegisterDomainStep>(RegisterDomainStep.REGISTER_FORM);

  const [registerDomainResponse, setRegisterDomainResponse] = React.useState<ca2domains.IRegisterDomainResponse | null>(
    null,
  );

  const switchToStep = (step: RegisterDomainStep) => {
    setPrevStep(currentStep);
    setCurrentStep(step);
  };

  const switchToPaymentStep = () => {
    switchToStep(RegisterDomainStep.PAYMENT);
  };

  const switchToConfirmationStep = () => {
    switchToStep(RegisterDomainStep.CONFIRMATION);
  };

  const handleCancelRegistration = () => {
    setRegisterDomainResponse(null);
    domainsStore.setRegisterDomainFormData(null);
    props.onCancelRegistration?.();
  };

  const handleRegisterDomain = async () => {
    if (!domainsStore.registerDomainFormData) {
      return;
    }

    const {res, error} = await domainsStore.registerDomain({
      ...domainsStore.registerDomainFormData,
      termYears: props.domainSuggestion.selectedTermYears,
    });

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiRegisterDomainError(error);
          notification.error(errorMessage);
        });

        return;
      }

      setRegisterDomainResponse(res);
      switchToConfirmationStep();
    }
  };

  const transitions = useOrderTransition({
    prevStep,
    currentStep,
  });

  return (
    <Div100vh className="page">
      <RegisterDomainTopBar currentStep={currentStep} onBack={props.onClickBackButton} />

      <div className="page__inner">
        {transitions((styles, step) =>
          step === RegisterDomainStep.REGISTER_FORM ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <RegisterDomainForm
                domainSuggestion={props.domainSuggestion}
                onSwitchToPaymentStep={switchToPaymentStep}
              />
            </animated.div>
          ) : step === RegisterDomainStep.PAYMENT ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <PaymentViewer
                orderInstance={props.domainSuggestion}
                onCancelPaymentOrder={handleCancelRegistration}
                onClickPayButton={handleRegisterDomain}
              />
            </animated.div>
          ) : registerDomainResponse && props.domainSuggestion && step === RegisterDomainStep.CONFIRMATION ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <RegisteredDomainConfirmation
                registerDomainResponse={registerDomainResponse}
                domainSuggestion={props.domainSuggestion}
                onCancelPaymentOrder={handleCancelRegistration}
              />
            </animated.div>
          ) : null,
        )}
      </div>
    </Div100vh>
  );
});

export default RegisterDomain;
