import {observer} from 'mobx-react';
import React from 'react';
import {animated, useTransition} from 'react-spring';
import {useStore} from '../../../stores/AppStore';
import {Plan} from '../../../stores/Plan';
import PlansFilter from './PlansFilter';
import PlansList from './PlansList';
import ServerTabs from './ServerTabs';

interface IChoosePlanStepProps {
  onSelectPlan?(plan: Plan): void;
  plans: Plan[];
  plansLoading: boolean;
  currentPlanId?: string | null;
}

export const ChoosePlanStep: React.FC<IChoosePlanStepProps> = observer((props) => {
  const {serversStore} = useStore();

  const handleChangeServerTab = (tabId: string) => {
    serversStore.changeServerTab(tabId);
  };

  const transitions = useTransition(serversStore.tabs.selectedId, {
    from: {
      opacity: 0,
    },
    enter: {opacity: 1},
    leave: {
      opacity: 0,
    },
  });

  return (
    <div className="choose-plan-step">
      <ServerTabs onChange={handleChangeServerTab} />

      <div className="choose-plan-step__inner">
        <div className="choose-plan-step__filters">
          {transitions((styles) => (
            <animated.div style={styles} className="animated-tab">
              <PlansFilter />
            </animated.div>
          ))}
        </div>

        <div className="choose-plan-step__content">
          {transitions((styles) => (
            <animated.div style={styles} className="animated-tab">
              <PlansList
                onSelectPlan={props.onSelectPlan}
                plans={props.plans}
                loading={props.plansLoading}
                currentPlanId={props.currentPlanId}
              />
            </animated.div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default ChoosePlanStep;
