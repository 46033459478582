import {Box, Button, Typography, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CpuIcon} from '../../assets/icons/cpu.svg';
import {ReactComponent as RamIcon} from '../../assets/icons/ram.svg';
import {ReactComponent as SpeedIcon} from '../../assets/icons/speed.svg';
import {ReactComponent as StorageIcon} from '../../assets/icons/storage.svg';
import {ReactComponent as ArrowIconDark} from '../../assets/image-icons/resize-server-arrow-dark.svg';
import {ReactComponent as ArrowIcon} from '../../assets/image-icons/resize-server-arrow.svg';
import PageContentLayout from '../../layouts/PageContentLayout';
import {useStore} from '../../stores/AppStore';
import {Plan} from '../../stores/Plan';

interface IProps {
  currentPlan: Plan;
  newPlan: Plan;
  onResizeServer?(): void;
}

const ResizeServerConfirmation: React.FC<IProps> = observer((props) => {
  const {lightThemeChosen} = useStore();
  const theme = useTheme();
  const {t} = useTranslation();

  const ArrowDown = lightThemeChosen ? ArrowIcon : ArrowIconDark;

  return (
    <PageContentLayout sx={{padding: theme.spacing(5)}}>
      <Box sx={{maxWidth: '500px', margin: '0 auto'}}>
        <Box className="resize-plan-card" sx={{border: 'none', padding: '0px'}}>
          <Typography variant="h3" gutterBottom>
            {t('resize_server_confirmation_title')}
          </Typography>
          <Typography variant="body1" sx={{marginBottom: theme.spacing(4)}}>
            {t('resize_server_confirmation_description')}
          </Typography>
        </Box>

        <Box className="resize-plan-card" sx={{marginBottom: theme.spacing(4)}}>
          <div className="resize-plan-card__row">
            <Typography className="resize-plan-card__title" variant="label3" color="body.tertiary">
              {t('resize_server_confirmation_current_configuration_title')}
            </Typography>

            <div className="resize-plan-card__hardware-info">
              <div className="resize-plan-card__hardware-info-item">
                <div className="resize-plan-card__hardware-info-icon">
                  <CpuIcon />
                </div>
                <Typography variant="body2">{props.currentPlan.cpuInfo}</Typography>
              </div>
              <div className="resize-plan-card__hardware-info-item">
                <div className="resize-plan-card__hardware-info-icon">
                  <RamIcon />
                </div>
                <Typography variant="body2">{props.currentPlan.ramSizeFormatted}</Typography>
              </div>
              <div className="resize-plan-card__hardware-info-item">
                <div className="resize-plan-card__hardware-info-icon">
                  <StorageIcon />
                </div>
                <Typography variant="body2">{props.currentPlan.storageSizeFormatted}</Typography>
              </div>
              <div className="resize-plan-card__hardware-info-item">
                <div className="resize-plan-card__hardware-info-icon">
                  <SpeedIcon />
                </div>
                <Typography variant="body2">{props.currentPlan.transferFormatted}</Typography>
              </div>
            </div>
          </div>

          <ArrowDown className="resize-plan-card__arrow-icon" />
        </Box>

        <Box className="resize-plan-card" sx={{borderBottomRightRadius: '0', borderBottomLeftRadius: '0'}}>
          <div className="resize-plan-card__row">
            <Typography
              className="resize-plan-card__title"
              variant="label3"
              color="body.tint"
              sx={{backgroundColor: theme.palette.success.primary}}
            >
              {t('resize_server_confirmation_new_configuration_title')}
            </Typography>

            <div className="resize-plan-card__hardware-info">
              <div className="resize-plan-card__hardware-info-item">
                <div className="resize-plan-card__hardware-info-icon">
                  <CpuIcon />
                </div>
                <Typography variant="body2">{props.newPlan.cpuInfo}</Typography>
              </div>
              <div className="resize-plan-card__hardware-info-item">
                <div className="resize-plan-card__hardware-info-icon">
                  <RamIcon />
                </div>
                <Typography variant="body2">{props.newPlan.ramSizeFormatted}</Typography>
              </div>
              <div className="resize-plan-card__hardware-info-item">
                <div className="resize-plan-card__hardware-info-icon">
                  <StorageIcon />
                </div>
                <Typography variant="body2">{props.newPlan.storageSizeFormatted}</Typography>
              </div>
              <div className="resize-plan-card__hardware-info-item">
                <div className="resize-plan-card__hardware-info-icon">
                  <SpeedIcon />
                </div>
                <Typography variant="body2">{props.newPlan.transferFormatted}</Typography>
              </div>
            </div>
          </div>
        </Box>
        <Box className="resize-plan-card" sx={{borderTop: 'none', borderTopRightRadius: '0', borderTopLeftRadius: '0'}}>
          <Typography variant="label3" color="body.tertiary" gutterBottom>
            {t('resize_server_confirmation_new_price_label')}
          </Typography>
          <Typography variant="subtitle1" color="body.primary" gutterBottom>
            {t('resize_server_confirmation_new_price_hourly', {price: props.newPlan.hourlyRateFormatter.formatted})}
          </Typography>
          <Typography variant="body3" color="body.tertiary">
            {t('resize_server_confirmation_new_price_monthly', {price: props.newPlan.monthlyRateFormatter.formatted})}
          </Typography>
        </Box>

        <Button
          variant="contained"
          fullWidth
          size="large"
          sx={{marginTop: theme.spacing(4)}}
          onClick={props.onResizeServer}
        >
          {t('action_button_confirm')}
        </Button>
      </Box>
    </PageContentLayout>
  );
});

export default ResizeServerConfirmation;
