import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {animated} from 'react-spring';
import {getApiResizeInstanceError} from '../../api/getApiError';
import Div100vh from '../../components/Div100vh';
import useIsFirstRender from '../../hooks/useIsFirstRender';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import {navigateToServerInfo} from '../../routes/navigateTo';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {Plan} from '../../stores/Plan';
import ChoosePlanStep from '../CreateServerPage/ChoosePlanStep/ChoosePlanStep';
import ResizeServerConfirmation from './ResizeServerConfirmation';
import ResizeServerPageTopBar from './ResizeServerPageTopBar';

export enum ResizeServerStep {
  CHOOSE_PLAN,
  CONFIRMATION,
}

export const ResizeServerPage: React.FC = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isFirst = useIsFirstRender();

  const {plansStore, instancesStore, notification} = useStore();

  const [currentStep, setCurrentStep] = React.useState<ResizeServerStep>(ResizeServerStep.CHOOSE_PLAN);
  const prevStep =
    currentStep === ResizeServerStep.CONFIRMATION ? ResizeServerStep.CHOOSE_PLAN : ResizeServerStep.CONFIRMATION;

  const [newPlan, setNewPlan] = React.useState<Plan | null>(null);

  const currentPlan = plansStore.upgradableFromPlan;

  const handelSelectPlan = (plan: Plan) => {
    setNewPlan(plan);

    setCurrentStep(ResizeServerStep.CONFIRMATION);
  };

  const handleBackButton = () => {
    setCurrentStep(ResizeServerStep.CHOOSE_PLAN);
  };

  const handleResizeServer = async () => {
    const instance = instancesStore.findInstanceByPlanId(currentPlan?.id);

    if (instance && newPlan) {
      const {res, error} = await instance.store.resizeInstance(newPlan.id);

      if (error) {
        notification.error(error.message);
      }

      if (res) {
        const {errors} = res;

        if (errors?.length) {
          errors.forEach((error) => {
            const errorMessage = getApiResizeInstanceError(error);
            notification.error(errorMessage);
          });

          return;
        }

        navigateToServerInfo(instance);
        plansStore.resetUpgradablePlanData();
        notification.success(t('resize_server_success_notification'));
      }
    }
  };

  React.useEffect(() => {
    if (!currentPlan) {
      navigate(Paths.Default, {replace: true});
    }
  }, [currentPlan, navigate]);

  React.useEffect(() => {
    return () => {
      if (!isFirst) {
        plansStore.resetUpgradablePlanData();
      }
    };
  }, [plansStore, isFirst]);

  const transitions = useOrderTransition({
    prevStep,
    currentStep,
  });

  return (
    <Div100vh className="page page--create-server">
      <ResizeServerPageTopBar currentStep={currentStep} onBack={handleBackButton} />

      <div className="page__inner">
        {transitions((styles, step) => (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            {step === ResizeServerStep.CHOOSE_PLAN ? (
              <ChoosePlanStep
                plans={plansStore.plansForUpgradable}
                plansLoading={plansStore.loading}
                onSelectPlan={handelSelectPlan}
                currentPlanId={currentPlan?.id}
              />
            ) : step === ResizeServerStep.CONFIRMATION && currentPlan && newPlan ? (
              <ResizeServerConfirmation
                currentPlan={currentPlan}
                newPlan={newPlan}
                onResizeServer={handleResizeServer}
              />
            ) : null}
          </animated.div>
        ))}
      </div>
    </Div100vh>
  );
});

export default ResizeServerPage;
