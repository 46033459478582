import {Box, Button, styled, Typography} from '@mui/material';
import validator from 'email-validator';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import TextFieldWithSubmitButton, {ITextFieldWithSubmitButtonsRef} from '../../components/TextFieldWithSubmitButton';
import PageContentLayout from '../../layouts/PageContentLayout';
import {useStore} from '../../stores/AppStore';

const BorderBox = styled(Box)(({theme}) => ({
  padding: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.border.primary}`,
}));

interface IProps {
  onSubmitUpdateEmail?(newEmail: string): Promise<void>;
  onClickChangePassword?(): void;
}

export const ProfileForm: React.FC<IProps> = observer((props) => {
  const {userStore, notification} = useStore();
  const {t} = useTranslation();

  const fullNameTextFieldRef = React.useRef<ITextFieldWithSubmitButtonsRef>(null);

  const fullNameFormik = useFormik<{newFullName: string}>({
    initialValues: {
      newFullName: userStore.fullName,
    },
    validationSchema: Yup.object({
      newFullName: Yup.string(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({newFullName}) => {
      const {error} = await userStore.updateFullName(newFullName);

      if (error) {
        notification.error(error?.message);
        return;
      }

      notification.success(t('profile_form_full_name_update_successful'));
      fullNameTextFieldRef.current?.hideButtons();
    },
  });

  const emailTextFieldRef = React.useRef<ITextFieldWithSubmitButtonsRef>(null);

  const emailFormik = useFormik<{newEmail: string}>({
    initialValues: {
      newEmail: userStore.profile?.email || '',
    },
    validationSchema: Yup.object({
      newEmail: Yup.string()
        .required(t('profile_form_email_required'))
        .test('is_email', t('profile_form_email_incorrect'), validator.validate),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({newEmail}) => {
      await props.onSubmitUpdateEmail?.(newEmail);

      emailTextFieldRef.current?.hideButtons();
    },
  });

  return (
    <Box sx={{maxWidth: 660, height: '100%'}}>
      <PageContentLayout>
        <BorderBox>
          <TextFieldWithSubmitButton
            ref={fullNameTextFieldRef}
            TextFieldProps={{
              name: 'newFullName',
              value: fullNameFormik.values.newFullName,
              label: t('profile_form_full_name_label'),
              placeholder: t('profile_form_full_name_placeholder'),
              error: !!fullNameFormik.errors.newFullName,
              helperText: fullNameFormik.errors.newFullName,
              onChange: fullNameFormik.handleChange,
              bottomGutter: false,
              onFocus: () => {
                fullNameTextFieldRef.current?.showButtons();
              },
              onBlur: () => {
                if (fullNameFormik.values.newFullName === userStore.fullName) {
                  fullNameTextFieldRef.current?.hideButtons();
                }
              },
            }}
            disableSubmitButton={fullNameFormik.values.newFullName === userStore.fullName}
            loading={fullNameFormik.isSubmitting}
            onSubmit={fullNameFormik.submitForm}
            onCancel={() => {
              fullNameFormik.setFieldValue('newFullName', userStore.fullName);
              fullNameTextFieldRef.current?.hideButtons();
            }}
            submitButtonText={t('action_button_save')}
          />
        </BorderBox>
        <BorderBox>
          <TextFieldWithSubmitButton
            ref={emailTextFieldRef}
            TextFieldProps={{
              name: 'newEmail',
              value: emailFormik.values.newEmail,
              label: t('profile_form_email_label'),
              placeholder: t('profile_form_email_placeholder'),
              error: !!emailFormik.errors.newEmail,
              helperText: emailFormik.errors.newEmail,
              onChange: emailFormik.handleChange,
              bottomGutter: false,
              onFocus: () => {
                emailTextFieldRef.current?.showButtons();
              },
              onBlur: () => {
                if (emailFormik.values.newEmail === userStore.email) {
                  emailTextFieldRef.current?.hideButtons();
                }
              },
            }}
            disableSubmitButton={emailFormik.values.newEmail === userStore.email}
            loading={emailFormik.isSubmitting}
            onSubmit={emailFormik.submitForm}
            onCancel={() => {
              emailFormik.setFieldValue('newEmail', userStore.email);
              emailTextFieldRef.current?.hideButtons();
            }}
          />
        </BorderBox>
        <BorderBox sx={{display: 'flex', alignItems: 'center'}}>
          <Box>
            <Typography variant="body3" color="body.primary">
              {t('profile_form_password_label')}
            </Typography>
            {userStore.profile?.hasPassword ? (
              <Typography>
                {t('profile_form_password_last_changed_at_label', {
                  date: userStore.lastPasswordChangeAtFormatted,
                })}
              </Typography>
            ) : (
              <Typography color="warning.primary">{t('profile_form_password_not_created_label')}</Typography>
            )}
          </Box>
          <Button
            sx={{marginLeft: 'auto'}}
            variant={userStore.profile?.hasPassword ? 'outlined' : 'contained'}
            size="large"
            onClick={props.onClickChangePassword}
          >
            {userStore.profile?.hasPassword ? t('action_button_change') : t('profile_form_password_create_button')}
          </Button>
        </BorderBox>
      </PageContentLayout>
    </Box>
  );
});

export default ProfileForm;
