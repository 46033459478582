import {
  Box,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Switch} from '../../components/UI/Switch';
import Typography from '../../components/UI/Typography';
import PageContentLayout from '../../layouts/PageContentLayout';
import {navigateToDomainInfo} from '../../routes/navigateTo';
import {useStore} from '../../stores/AppStore';
import {Domain} from '../../stores/Domain';
import {ModalType} from '../../stores/ModalsStore';
import {BALANCE_OPERATION_TITLES} from '../BillingPage/OperationTypeFilter';
import {DomainInfoTabs} from './DomainInfoViewer';

const BorderBox = styled(Box)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
}));

interface IProps {
  domain: Domain;
}

export const DomainBilling: React.FC<IProps> = observer((props) => {
  const {modals} = useStore();
  const {t} = useTranslation();
  const theme = useTheme();

  const handleToggleRenewal = () => {
    if (props.domain.autoRenew) {
      modals.open(ModalType.CONFIRM, {
        maxWidth: 'sm',
        headerText: t('modal_confirm_disable_domain_auto_renewal_header_text'),
        title: <Typography variant="body1">{t('modal_confirm_disable_domain_auto_renewal_title')}</Typography>,
        submitButtonText: t('modal_confirm_disable_domain_auto_renewal_submit'),
        submitButtonColor: 'primary',
        content: <Typography variant="body1">{t('modal_confirm_disable_domain_auto_renewal_text')}</Typography>,
        callbacks: {
          submit: props.domain.toggleAutoRenew,
        },
      });
    } else {
      props.domain.toggleAutoRenew();
    }
  };

  const handleRenewNow = () => {
    navigateToDomainInfo(props.domain, DomainInfoTabs.RenewPayment);
  };

  const isAutoRenew = !!props.domain.autoRenew;

  return (
    <Box sx={{maxWidth: 815, height: '100%'}}>
      <PageContentLayout>
        <BorderBox sx={{alignItems: 'center', justifyContent: 'space-between'}}>
          <Box>
            <Typography variant="body3" color="body.tertiary">
              {t('domain_billing_next_payment_label')}
            </Typography>

            <Box sx={{display: 'flex', alignItems: 'end', justifyContent: 'space-between'}}>
              <Typography variant="h3">{props.domain.renewalPriceFormatter.formatted}</Typography>
              <Typography
                variant="body1"
                color="body.tertiary"
                sx={{marginLeft: theme.spacing(2), marginBottom: theme.spacing(0)}}
              >
                {t('domain_billing_days_left_until_renewal', {days: props.domain.daysUntilExpiration})}
              </Typography>
            </Box>
          </Box>
          <Button variant="contained" color="primary" size="large" onClick={handleRenewNow}>
            {t('domain_billing_renew_now_button')}
          </Button>
        </BorderBox>

        <BorderBox sx={{alignItems: 'center', justifyContent: 'space-between'}}>
          <Box>
            <Typography variant="body3">{t('domain_billing_auto_renew_label')}</Typography>
            <Typography variant="body1">
              {isAutoRenew ? t('domain_billing_enabled_label') : t('domain_billing_disabled_label')}
            </Typography>
          </Box>

          <div>
            <Switch onChange={handleToggleRenewal} checked={isAutoRenew} />
          </div>
        </BorderBox>

        {props.domain.upcomingPayments.length ? (
          <BorderBox sx={{flexDirection: 'column'}}>
            <Typography variant="label2" gutterBottom>
              {t('domain_billing_upcoming_renewals_title')}
            </Typography>

            <TableContainer>
              <Table sx={{borderCollapse: 'separate'}}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('domain_billing_upcoming_payment_table_date_column')}</TableCell>
                    <TableCell align="right">{t('domain_billing_upcoming_payment_table_amount_column')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.domain.upcomingPayments.map(({dateFormatted, amountFormatter}) => (
                    <TableRow key={dateFormatted}>
                      <TableCell>{dateFormatted}</TableCell>
                      <TableCell align="right">{amountFormatter.formatted}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </BorderBox>
        ) : null}

        {props.domain.balanceOperations.length ? (
          <BorderBox sx={{flexDirection: 'column'}}>
            <Typography variant="label2" gutterBottom>
              {t('domain_billing_history_title')}
            </Typography>

            <TableContainer>
              <Table sx={{borderCollapse: 'separate'}}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('domain_billing_upcoming_payment_table_date_column')}</TableCell>
                    <TableCell>{t('domain_billing_upcoming_payment_table_type_column')}</TableCell>
                    <TableCell align="right">{t('domain_billing_upcoming_payment_table_amount_column')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.domain.balanceOperations.map(({createdAtFormatted, amountFormatter, type}) => (
                    <TableRow key={createdAtFormatted}>
                      <TableCell>{createdAtFormatted}</TableCell>
                      <TableCell>{type ? BALANCE_OPERATION_TITLES[type] : ''}</TableCell>
                      <TableCell align="right">{amountFormatter.formatted}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </BorderBox>
        ) : null}
      </PageContentLayout>
    </Box>
  );
});

export default DomainBilling;
