import React from 'react';

import {useOrigin100vh} from '../../hooks/useOrigin100vh';
import {useStore} from '../../stores/AppStore';
import {getStyleProperty} from '../../utils/styleProperty';

export function use100vh() {
  const originHeight = useOrigin100vh();
  const {appVersionUpdater, utils} = useStore();

  const [height, setHeight] = React.useState<number | null>(null);

  const updateAppBarHeight = parseInt(getStyleProperty('--update-app-top-bar-height'), 10);
  const systemAlertHeight = parseInt(getStyleProperty('--system-alert-bar-height'), 10);

  React.useEffect(() => {
    if (originHeight) {
      let height = originHeight;

      if (appVersionUpdater.hasNewVersion) {
        height -= updateAppBarHeight;
      }

      if (utils.hasAlertMessage) {
        height -= systemAlertHeight;
      }

      setHeight(height);
    }
  }, [originHeight, updateAppBarHeight, systemAlertHeight, utils.hasAlertMessage, appVersionUpdater.hasNewVersion]);

  return height || originHeight;
}

export default use100vh;
