import React from 'react';
import Div100vh from '../../components/Div100vh';

import {Box, Typography} from '@mui/material';
import {useNavigate} from 'react-router';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import FooterMenu from './FooterMenu';
import ProfileMenu from './ProfileMenu';
import SidebarHead from './SidebarHead';
import SystemMenu from './SystemMenu';
import UserBalance from './UserBalance';

export const Sidebar: React.FC = React.memo(() => {
  const {layoutStore, version} = useStore();
  const navigate = useNavigate();

  const handleCreateServer = () => {
    navigate(Paths.CreateServer);
  };

  const handleMouseEnter = () => {
    layoutStore.sidebar.setIsMouseHover(true);
  };

  const handleMouseLeave = () => {
    layoutStore.sidebar.setIsMouseHover(false);
  };

  React.useEffect(() => {
    return () => {
      layoutStore.sidebar.setIsMouseHover(false);
    };
  }, [layoutStore.sidebar]);

  return (
    <Div100vh className="sidebar" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SidebarHead onCreateServer={handleCreateServer} />
      <SystemMenu />
      <FooterMenu />
      <ProfileMenu />
      <UserBalance />

      <Box sx={{textAlign: 'center'}}>
        <Typography variant="body3" color="body.placeholder">
          {`version ${version}`}
        </Typography>
      </Box>
    </Div100vh>
  );
});

export default Sidebar;
