import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import browserHistory from '../../routes/browserHistory';
import Paths, {DomainRouteParams} from '../../routes/Paths';
import logger from '../../utils/logger';
import {AppStore} from '../AppStore';
import Domain from './Domain';

export class DomainsView {
  constructor(public app: AppStore) {
    makeObservable(this);
  }

  @observable isSearchDomainStart: boolean = false;
  @observable isSearchingDomains: boolean = false;

  @observable activeDomain: Domain | null = null;

  @computed get domainsStore() {
    return this.app.domainsStore;
  }

  @action setActiveDomain = (domain: Domain | null) => {
    domain?.init();

    this.activeDomain = domain;
  };

  openDomainByParams = (params: DomainRouteParams) => {
    const domainId = params.domainId ? Long.fromString(params.domainId) : null;

    if (domainId) {
      const foundDomain = this.app.domainsStore.findById(domainId);

      if (foundDomain) {
        this.setActiveDomain(foundDomain);
        return;
      }
    }

    logger.error(`%c---> DomainView:openDomainByID: [${domainId}] - domain not found`, 'color: red');

    browserHistory.push(Paths.Servers);
    return;
  };

  @action searchDomain = async (searchText: string) => {
    this.isSearchingDomains = true;
    this.isSearchDomainStart = true;

    this.domainsStore.search.clearDomainSuggestions();

    const {res, error} = await this.app.domainsStore.search.searchDomain(searchText, this.domainsStore.search.selectedTldNames);

    if (error) {
      this.app.notification.error(error.message);
    }

    this.isSearchingDomains = false;

    return {res, error};
  };

  @action reset = () => {
    this.isSearchDomainStart = false;

    this.app.domainsStore.search.resetFilter();
  };
}

export default DomainsView;
