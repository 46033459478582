import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';

import {useStore} from '../stores/AppStore';

import {Button, useTheme} from '@mui/material';

const VersionUpdateAppBar = observer(() => {
  const theme = useTheme();
  const {appVersionUpdater} = useStore();
  const {t} = useTranslation();

  if (!appVersionUpdater.hasNewVersion) {
    return null;
  }

  return (
    <div className="update-app-bar">
      {t('component_app_version_updater_available_new_version')}
      <Button color="success" sx={{marginLeft: theme.spacing(2)}} onClick={appVersionUpdater.update}>
        {t('component_app_version_updater_button')}
      </Button>
    </div>
  );
});

export default VersionUpdateAppBar;
